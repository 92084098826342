import React from "react"
import { PropTypes as HeroImageProps } from "../components/layouts/hero/hero-image"
import { PropTypes as ReleaselistProps } from "../components/layouts/release-notes/release-list"
import { PropTypes as SeoProps } from "../components/layouts/seo/seo"
import Release from "../images/illustrations/docs.svg"

export const seo: SeoProps = {
  description: "We strive to make the Ory Network the best solution for you.",
  title: "Ory Network Release Notes",
}

export const hero: HeroImageProps = {
  id: "release.hero",
  title: (
    <>
      <span className={"is-themed-primary"}>The Ory Network</span> Release Notes
    </>
  ),
  description: "We strive to make the Ory Network the best solution for you.",
  buttons: [
    {
      ctaTitle: "Explore the Ory Network",
      style: "filled",
      to: "/cloud",
    },
  ],
  image: (
    <img
      className="responsive"
      loading="lazy"
      alt="foo"
      src={Release.toString()}
    />
  ),
}

export const featureList: ReleaselistProps = {
  id: "release.featurelist",
  slant: true,
  background: "grey",
  features: [
    {
      title: "Latest releases",
      description: (
        <>
          Ory Cloud has now better compatibility with Ory Open Source features,
          new Network Policies, Cilium and general performance improvements.
        </>
      ),
    },
  ],
}
